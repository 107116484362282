import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

interface Milestone {
    dateRangeKey: string;
    descriptionKey: string;
    cx: number;
    color: string;
    number: number;
}

@Component({
    selector: 'app-home-two',
    templateUrl: './home-two.component.html',
    styleUrls: ['./home-two.component.scss']
})
export class HomeTwoComponent implements OnInit {
    constructor(private translate: TranslateService) {
        const language = localStorage.getItem('language') || 'it';
        translate.setDefaultLang(language);
    }

    ngOnInit(): void {
    }

    milestones: Milestone[] = [
        { dateRangeKey: 'Milestones.FirstDateRange', descriptionKey: 'Milestones.FirstFeatureDescription', cx: 10, color: '#E91E63', number: 1 },
        { dateRangeKey: 'Milestones.SecondDateRange', descriptionKey: 'Milestones.SecondFeatureDescription', cx: 30, color: '#009688', number: 2 },
        { dateRangeKey: 'Milestones.ThirdDateRange', descriptionKey: 'Milestones.ThirdFeatureDescription', cx: 50, color: '#3F51B5', number: 3 },
        { dateRangeKey: 'Milestones.FourthDateRange', descriptionKey: 'Milestones.FourthFeatureDescription', cx: 70, color: '#FF9800', number: 4 },
        { dateRangeKey: 'Milestones.FifthDateRange', descriptionKey: 'Milestones.FifthFeatureDescription', cx: 90, color: '#00BCD4', number: 5 },
        { dateRangeKey: 'Milestones.SixthDateRange', descriptionKey: 'Milestones.SixthFeatureDescription', cx: 90, color: '#00BCD4', number: 6 },
    ];

    switchLanguage(language: string) {
        this.translate.use(language);
        localStorage.setItem('language', language);
    }

    getDateRange(milestone: Milestone): string {
        return this.translate.instant(milestone.dateRangeKey);
    }

    getDescription(milestone: Milestone): string {
        return this.translate.instant(milestone.descriptionKey);
    }
}
